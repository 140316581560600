import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the ProductFilter Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  connect () {
    super.connect()
    // add your code here, if applicable
    console.log('Hello')
  }

  static targets = [
    'modelPlaceholder',
    'modeltypePlaceholder',
    'displacementPlaceholder',
      'waitModel',
      'waitModeltype',
      'waitDisplacement'
  ]

  beforeReflex (element, reflex) {
    super.beforeReflex(element, reflex)

    if (reflex === 'FrontProductFilterReflex#select_make')
      this.modelPlaceholderTarget.remove()
      this.waitModelTarget.hidden = false

    if (reflex === 'FrontProductFilterReflex#select_model')
      this.modeltypePlaceholderTarget.remove()
    this.waitModeltypeTarget.hidden = false

    if (reflex === 'FrontProductFilterReflex#select_model_type')
      this.displacementPlaceholderTarget.remove()
    this.waitDisplacementTarget.hidden = false

  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="product-filter" to your markup alongside
   * data-reflex="ProductFilter#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->ProductFilter#dance" data-controller="product-filter">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ProductFilter#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "ProductFilter#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = 'Danced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "Couldn't dance!"
  // }
}
