/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("trix")
// require("@rails/actiontext")
require.context('../images', true)


// Tailwind CSS
import "stylesheets/frontend.scss"
import "lightgallery.js/dist/css/lightgallery.css"
require("lightgallery.js/dist/js/lightgallery")

// Stimulus controllers
import "controllers"

// Jumpstart Pro & other Functionality
// import "src/actiontext"
import "src/confirm"
// import "src/direct_uploads"
import "src/forms"
import "src/timezone"
// import "src/tooltips"

import LocalTime from "local-time"
LocalTime.start()


window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
document.addEventListener('turbolinks:load', function () {
  // If we have a scroll position AND we're on the same page
 window.scrollTo(0,0)

    const menuBtn = document.querySelector('.menu-btn');
    const fullMenu = document.querySelector('#fullnavcontainer');
    let menuOpen = false;
    menuBtn.addEventListener('click', () => {
        if(!menuOpen) {
            menuBtn.classList.add('open');
            fullMenu.classList.add('open');
            menuOpen = true;
        } else {
            menuBtn.classList.remove('open');
            fullMenu.classList.remove('open');
            menuOpen = false;
        }
    });

}, false)

// Start Rails UJS
Rails.start()
